.list-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 20%;
  margin: 0px 10px 0px 10px;
}

.css-mhc70k-MuiGrid-root {
  margin-top: 0px !important;
}
