.css-m9glnp-MuiPaper-root-MuiDialog-paper {
  background-color: transparent !important;
}

.display-image {
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 1200px;
}

.image-informe {
  object-fit: contain;
  width: 98%;
}

.imagen-con-x {
  position: relative;
  display: inline-block;
}

.iconoDeleteInforme {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  background-color: #e2e2e2;
  color: white;
  padding: 5px;
  font-weight: bold;
}

.iconoDeleteInforme:hover {
  opacity: 1;
  background-color: red;
}
