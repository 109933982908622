.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #eaeaea;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  width: 50px;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  width: 100%;
}

.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  min-width: 100px !important;
}
