@import "../fonts/OpenSans2.ttf";

#sidebar {
  background: #f4f4f4;
/*   height: 90%; */
  z-index: 3;
  width: 190px;
  margin: 0;
  top: 73px;
  padding: 0;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  border-right: #ccc solid 1px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transition: all 0.3s;
}

#sidebar.closed {
  width: 0;
}

.linkItem {
  background-color: white;
  border-top: 1px solid #ccc;
  padding: 10px 0px 10px 25px;
  transition: background-color 0.45s;
}

.list-group-item {
  display: flex;
  flex-direction: column;
}

a.linkItem:hover {
  background-color: #ffe600;
}

#sidebar ul {
  padding: 0;
  width: 100%;
  height: auto;
  margin-top: "10%";
}

#sidebar ul li.sidebar-row {
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  color: #808080;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

#sidebar ul a {
  text-decoration: none;
}

#sidebar ul li.sidebar-row:hover {
  cursor: pointer;
  background-color: #ffe600; /* Color GCBA*/
  color: #000;
}

#sidebar ul li.sidebar-row div.icono {
  flex: 30%;
  display: grid;
  place-items: center;
}

#sidebar ul li.sidebar-row div.titulo {
  flex: 70%;
  font-family: "Open Sans", sans-serif;
}
